import { filter, first, flatMap, groupBy, includes, isEmpty, some, sortBy, values } from 'lodash-es';
import type { FormFields, Rebate } from '../typedefs';
import { REBATE_DISCLAIMER } from './constants';
import {
  COST_DISCOUNT_LABELS,
  REBATE_INCOME_QUALIFICATION_CRITERIA_FOR_HUD_AMI_KEYS,
  RebateIncomeQualificationCriteria,
  RebateProviderType,
  type CostDiscountType,
  type SelectedHudAmi,
} from './enums';
import FilterUtil from './filterUtil';
import StringUtil from './stringUtil';

const ALL_REBATE_INCOME_QUALIFICATION_CRITERIA = values(REBATE_INCOME_QUALIFICATION_CRITERIA_FOR_HUD_AMI_KEYS);

// This converts the SelectedHudAmi input to the RebateIncomeQualificationCriteria values for which rebates should match.
// Generally, a lower AMI % will satisfy any criteria that are higher %s.
// Ex: HUD_AMI_BELOW_OR_AT_50PCT will match rebates specifying HUD_AMI_BELOW_OR_AT_50PCT, HUD_AMI_BELOW_OR_AT_80PCT, and above.

// Since the legit AMI values of SelectedHudAmi and RebateIncomeQualificationCriteria match, we can basically directly
// convert the input to the criteria type.
function determineImpliedIncomeQualificationCriteria(
  selectedHudAmi: SelectedHudAmi | undefined,
): RebateIncomeQualificationCriteria[] {
  if (!selectedHudAmi) return [];

  const selectedHudAmiAsCriteria = RebateIncomeQualificationCriteria[selectedHudAmi];
  if (!selectedHudAmiAsCriteria) return [];

  const criteria = filter(ALL_REBATE_INCOME_QUALIFICATION_CRITERIA, (criterion) => criterion >= selectedHudAmi);
  return criteria;
}

function sortRebatesByAmount(rebates: Rebate[]) {
  return sortBy(rebates, (rebate) => -1 * rebate.amount);
}

function pickLargest(rebates: Rebate[]): Rebate | undefined {
  const sortedRebates = sortRebatesByAmount(rebates);
  return first(sortedRebates);
}

function pickLargestRebatesByName(rebates: Rebate[]): Rebate[] {
  const rebatesByDisplayName = groupBy(rebates, (rebate) => rebate.name);
  const largestRebates = flatMap(values(rebatesByDisplayName), (rebates) => pickLargest(rebates));
  return filter(largestRebates) as Rebate[];
}

const RebateUtil = {
  sortRebatesByAmount,
  pickLargest,
  pickLargestRebatesByName,

  generatePresentableDescription(rebate: Rebate, config: { omitUrl?: boolean; overrideAmount?: number } = {}) {
    const { amount, description, url, incomeQualificationUrl } = rebate;
    const effectiveAmount = config.overrideAmount ? config.overrideAmount : amount;
    const amountStr = StringUtil.formatCurrency(effectiveAmount);

    const descriptionCopy = `${description}`;
    let updated = descriptionCopy.replace(/\{amount\}/g, amountStr);
    updated = `${updated}\n\n${REBATE_DISCLAIMER}`;

    if (url && !config?.omitUrl) {
      updated = `${updated}\n\n For details and requirements, visit: ${url}`;
    }

    if (incomeQualificationUrl && !config?.omitUrl) {
      updated = `${updated}\n\n For details on eligibility and income thresholds, visit: ${url}`;
    }

    return updated;
  },

  filterRebatesForProject(rebates: Rebate[] | undefined, formData: FormFields, showIncomeBracketedRebates: boolean) {
    if (!rebates || isEmpty(rebates)) return [];

    const { matches: iqRebates, nonmatches: nonIqRebates } = FilterUtil.splitListByFilterMatch(
      rebates,
      (rebate) => !!rebate.bucket?.incomeQualified || false,
    );

    const { showIncomeQualifiedRebates, selectedHudAmi } = formData;

    if (isEmpty(iqRebates) || !showIncomeQualifiedRebates) {
      const filteredNonIqRebates = pickLargestRebatesByName(nonIqRebates);
      return sortRebatesByAmount(filteredNonIqRebates);
    }

    const iqCriteria = determineImpliedIncomeQualificationCriteria(selectedHudAmi);

    const matchingIqRebates = filter(iqRebates, (rebate) => {
      const { incomeQualificationCriteria } = rebate.bucket || {};
      if (isEmpty(incomeQualificationCriteria)) return true;
      if (isEmpty(iqCriteria)) return false;
      return some(iqCriteria, (iqc) => includes(incomeQualificationCriteria, iqc));
    });

    const results = [...nonIqRebates, ...matchingIqRebates];
    const filteredResults = pickLargestRebatesByName(results);
    return sortRebatesByAmount(filteredResults);
  },

  splitIntoGovernmentAndOtherRebates(rebates: Rebate[] | undefined): { government: Rebate[]; other: Rebate[] } {
    const { matches: government, nonmatches: other } = FilterUtil.splitListByFilterMatch(
      rebates || [],
      (rebate) => rebate.providerType === RebateProviderType.GOVERNMENT,
    );
    return { government, other };
  },

  getRebateTotal(rebates?: Rebate[]) {
    if (!rebates || isEmpty(rebates)) return 0;
    return rebates.reduce((total, rebate) => total + rebate.amount, 0);
  },

  getRebateGroupLabel(rebates: Rebate[], titleCase: boolean = false): string {
    if (!rebates || isEmpty(rebates)) return '';

    const labels: string[] = [];
    Object.keys(COST_DISCOUNT_LABELS).forEach((type) => {
      const label = COST_DISCOUNT_LABELS[type as CostDiscountType];
      if (rebates.find((rebate) => rebate.type === type))
        labels.push(`${titleCase ? StringUtil.toTitleCase(label) : label}s`);
    });

    if (labels.length < 2) {
      return labels.join('');
    }

    labels[labels.length - 1] = `and ${labels[labels.length - 1]}`;

    if (labels.length === 2) {
      return labels.join(' ');
    }
    return labels.join(', ');
  },
};

export default RebateUtil;
